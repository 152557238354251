<div class="bg-color d-flex justify-content-center align-items-center flex-column p-2" style="overflow-y: scroll;">
  <mat-card
    class="col-12 col-sm-9 col-md-6 col-lg-4 border p-4 d-flex flex-column justify-content-center align-items-center">
    <img fetchpriority="high" routerLink="/" mat-card-image src="assets/images/hisimLogo.png" class="p-2 w-75"
      style="cursor: pointer" />
    <mat-card-content>
      <form [formGroup]="registrationForm">
        <mat-form-field class="col-12 p-0 m-0" appearance="outline" color="accent">
          <mat-label>Select Products</mat-label>
          <mat-select formControlName="products" multiple>
            <mat-option *ngFor="let productType of productTypes" [value]="productType.id">
              {{ productType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="col-6 p-0 m-0">
          <mat-label>First Name</mat-label>
          <input required formControlName="firstName" matInput />
          <mat-error *ngIf="isControlInvalid('firstName')">Enter your first name</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="col-6 p-0 m-0">
          <mat-label>Last Name</mat-label>
          <input required formControlName="lastName" matInput />
          <mat-error *ngIf="isControlInvalid('lastName')">Enter your last name</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="col-6 p-0 m-0">
          <mat-label>Username</mat-label>
          <input required formControlName="username" matInput />
          <mat-error *ngIf="isControlInvalid('username')">Enter your username</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="col-6 p-0 m-0">
          <mat-label>Email</mat-label>
          <input required formControlName="email" matInput />
          <mat-error *ngIf="isControlInvalid('email')">Enter your email</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="col-12 p-0 m-0">
          <mat-label>Phone</mat-label>
          <input required formControlName="phone" type="tel" matInput />
          <mat-error *ngIf="isControlInvalid('phone')">Enter your phone</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="col-12 p-0 m-0">
          <mat-label>Firm Name</mat-label>
          <input required formControlName="firmName" matInput />
          <mat-error *ngIf="isControlInvalid('firmName')">Enter your firm name</mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 p-0 m-0" appearance="outline" color="accent">
          <mat-label>Firm Working Field</mat-label>
          <mat-select formControlName="firmField">
            <mat-option *ngFor="let firmField of firmFields" [value]="firmField">
              {{ firmField }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="register()" [disabled]="isLoading" mat-raised-button color="accent" class="text-white col-12">
          Send Registration Request
        </button>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
      <p *ngIf="!isLoading" class="text-center m-2">{{ statusMessage }}</p>
    </mat-card-footer>
  </mat-card>
</div>